export const PRINT_FETCH_REQUEST = "PRINT_FETCH_REQUEST";
export const PRINT_FETCH_SUCCESS = "PRINT_FETCH_SUCCESS";
export const PRINT_FETCH_FAIL = "PRINT_FETCH_FAIL";

export const DIGITAL_FETCH_REQUEST = "DIGITAL_FETCH_REQUEST";
export const DIGITAL_FETCH_SUCCESS = "DIGITAL_FETCH_SUCCESS";
export const DIGITAL_FETCH_FAIL = "DIGITAL_FETCH_FAIL";


export const MOTION_FETCH_REQUEST = "MOTION_FETCH_REQUEST";
export const MOTION_FETCH_SUCCESS = "MOTION_FETCH_SUCCESS";
export const MOTION_FETCH_FAIL = "MOTION_FETCH_FAIL";

export const UX_FETCH_REQUEST = "UX_FETCH_REQUEST";
export const UX_FETCH_SUCCESS = "UX_FETCH_SUCCESS";
export const UX_FETCH_FAIL = "UX_FETCH_FAIL";