import React from 'react';
import Logo from '../images/logo@2x.png'


export default function Footer(){
    return (
        <>
        <div className=" bg-linear-pink-invert pb-12" id="contact">
            <div className="mx-auto pt-10 lg:pt-20 flex flex-col items-center justify-center bg-gray-800">
                <div>
                <svg version="1.1" baseProfile="tiny" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink"
	 x="0px" y="0px" viewBox="0 0 595.3 841.9" overflow="visible">
<g>
	<path fill="#E61E45" d="M429.5,349.6c-5.3,0-9.8-1.4-13.5-4.3c-3.7-2.8-5.6-5.9-5.6-9.2v-9.5c-3.3,6.8-9.2,12.8-17.6,18.1
		c-8.4,5.3-17.8,7.9-28.1,7.9c-10.3,0-19.8-3.2-28.6-9.5c-8.8-6.4-15.8-14.7-21-25c-5.3-10.3-7.9-21.3-7.9-32.9v-27
		c0-12.3,2.6-23.5,7.9-33.5c5.3-10.1,12.3-18.1,21-24.2c8.8-6,18.5-9,29.3-9c10.3,0,19.1,2.6,26.3,7.7c7.2,5.2,12.6,10.9,16.1,17.3
		V115.5c0-3.9,2.1-7.1,6.2-9.5c4.2-2.4,9.3-3.6,15.5-3.6c2.3,0,4.4,0.2,6.4,0.5C402,64.8,352.6,40.7,297.6,40.7
		c-58,0-109.8,26.7-143.6,68.6h57.5c14.5,0,27.6,2.1,39.3,6.2c11.7,4.2,21.1,11.2,28.1,21.2c7,10,10.5,23.7,10.5,41.3
		c0,12.7-1.9,23.5-5.6,32.4c-3.7,8.9-8.8,16-15.3,21.4c-6.5,5.4-13.9,9.4-22.2,12l43.4,79.9c0.4,0.9,0.8,1.8,1,2.8
		c0.2,1,0.3,1.8,0.3,2.5c0,3.5-1.3,7-3.9,10.5c-2.6,3.5-6,6.4-10,8.5c-4.1,2.2-8.3,3.3-12.7,3.3c-2.9,0-5.6-0.9-8.4-2.6
		c-2.7-1.8-4.9-4.3-6.4-7.6L203,250.3h-27.6v85.8c0,4.4-2.2,7.7-6.6,10c-2.6,1.4-5.4,2.3-8.5,2.9c33.8,37.5,82.8,61.2,137.3,61.2
		c54.5,0,103.4-23.6,137.2-61C433.2,349.4,431.4,349.6,429.5,349.6z"/>
	<path fill="#E61E45" d="M450.2,329.6c20.3-29.7,32.2-65.5,32.2-104.2c0-38.6-11.9-74.5-32.2-104.2V329.6z"/>
	<path fill="#E61E45" d="M132.6,142.3c-12.6,25-19.7,53.2-19.7,83.1c0,29.9,7.1,58.1,19.7,83.1V142.3z"/>
	<path fill="#E61E45" d="M237.2,208.9c6.4-5.3,9.5-14.2,9.5-27c0-13.2-3.2-22.3-9.5-27.5c-6.4-5.1-14.9-7.7-25.6-7.7h-36.2v70h36.2
		C222.3,216.8,230.8,214.2,237.2,208.9z"/>
	<path fill="#E61E45" d="M392.2,232.2c-4.5-2.4-9-3.6-13.6-3.6c-5.3,0-10.1,1.5-14.6,4.4c-4.5,3-8,6.6-10.5,11
		c-2.5,4.4-3.8,9.1-3.8,14.1v27c0,5,1.3,9.9,3.8,14.5c2.5,4.6,6,8.4,10.4,11.3c4.4,3,9.2,4.4,14.5,4.4c5.7,0,10.7-1.6,15.1-4.8
		c4.4-3.2,7.9-6.7,10.5-10.5c2.6-3.8,3.9-6.8,3.9-9v-32.9c0-6.1-1.5-11.4-4.4-15.8C400.4,238,396.7,234.6,392.2,232.2z"/>
</g>
</svg>
                </div>
                <div className="text-black flex flex-col text-center md:items-center f-f-l pt-3 font-body text-crimson">
                    <div className="my-6 f-f-l">
                        <ul className="md:flex items-center">
                            <li className=" md:mr-6 cursor-pointer pt-4 lg:py-0">Instagram</li>
                            <li className=" md:mr-6 cursor-pointer pt-4 lg:py-0">TikTok</li>
                            <li className=" md:mr-6 cursor-pointer pt-4 lg:py-0">Dribble</li>
                            <li className=" md:mr-6 cursor-pointer pt-4 lg:py-0"><a href="https://www.facebook.com/ryan.davies.71">facebook</a></li>
                        </ul>
                    </div>
                    <div className="text-sm flex flex-col md:items-center  text-gray-600 mb-10 f-f-l">
                    <div>
                      <img src={Logo} style={{maxHeight: 50}}/>
                    </div>
                        <p> © 2021 Ryan Davies. All rights reserved</p>
                    </div>
                </div>
                <div className="w-9/12  h-0.5 bg-crimson rounded-full" />
                <div className="flex justify-between items-center pt-12">
                </div>
            </div>
        </div>
        </>
    )
}